import React from 'react';
import NewWordIcon from '../../../assets/new-word.svg';
import MoonIcon from '../../../assets/moon-01.svg';
import { useLanguage } from '../../../languages/LanguageContext';
import { GameTag } from '../../../types';

interface BadgeProps {
  tag?: GameTag;
}

const Badge: React.FC<BadgeProps> = ({ tag }) => {
  let content: JSX.Element | null = null;
  const { getTextLangFrom } = useLanguage();

  switch (tag) {
    case 'new':
      content = (
        <>
          <img src={NewWordIcon} alt="New" className="h-5 w-5" />
          {getTextLangFrom('GameCardTag-new-word')}
        </>
      );
      break;
    case 'review':
      content = (
        <>
          <img src={MoonIcon} alt="Review" className="h-5 w-5" />
          {getTextLangFrom('GameCardTag-previews-mistake')}
        </>
      );
      break;
    default:
      break;
  }

  return (
    <div
      className={`flex items-center gap-2 h-5 text-sm font-semibold uppercase tracking-tight text-text-tertiary`}
      style={{ letterSpacing: '0.01em' }}
    >
      {content}
    </div>
  );
};

export default Badge;
