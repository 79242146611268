import { TranslationTooltip } from "../../../types";

const setTooltipPosition = (tooltip: TranslationTooltip, rect?: DOMRect, event?: React.MouseEvent<HTMLSpanElement>) => {
  const maxTooltipWidth = 350;
  const progressBarHeight = 64;

  if (rect) {
      tooltip.position.top = rect.bottom + 10 + window.scrollY - progressBarHeight;

      if (rect.left + maxTooltipWidth > window.innerWidth) {
          tooltip.position.right = window.innerWidth - rect.right + window.scrollX;
      } else {
          tooltip.position.left = rect.left + window.scrollX;
      }
  } else if (event) {
      tooltip.position.top = event.clientY + window.scrollY + 16 - progressBarHeight;
    
      // TODO: to improve, evaluate the size of the tooltip and then translate it to the right or left
      if (event.clientX > window.innerWidth / 2) {
        const distanceToLeft = event.clientX;
        const translationToRight = Math.max(maxTooltipWidth - distanceToLeft, 0);
        const clickToTooltip = window.innerWidth - event.clientX + window.scrollX;
        //console.log("Distance to left: ", distanceToLeft, "Translation to right: ", translationToRight, "Click to tooltip: ", clickToTooltip);

        tooltip.position.right = Math.max(12, clickToTooltip - translationToRight);
      } else {
        const distanceToRight = window.innerWidth - event.clientX;
        const translationToLeft = Math.max(maxTooltipWidth - distanceToRight, 0);
        const clickToTooltip = event.clientX + window.scrollX;
        //console.log("Distance to right: ", distanceToRight, "Translation to left: ", translationToLeft, "Click to tooltip: ", clickToTooltip);
        
        tooltip.position.left = Math.max(12, clickToTooltip - translationToLeft);
      }
  }

  // console.log("Tooltip position: ", tooltip.position);

  return tooltip;
}

export default setTooltipPosition;