import React from 'react';
import EmptyStateImageDark from '../../assets/empty-state-cloud-dark.svg';
import EmptyStateImageLight from '../../assets/empty-state-cloud-light.svg';
import Button from './Button';
import { EmptyStateProps } from '../../types';
import { useAuth } from '../../authentification/AuthContext';

const EmptyState: React.FC<EmptyStateProps> = (emptyStateProps) => {
  const { user } = useAuth();
  const EmptyStateImage = user?.theme.includes('dark') ? EmptyStateImageDark : EmptyStateImageLight;

  return (
    <div className="flex flex-col items-center gap-4 py-10 text-center w-full">
      <img src={EmptyStateImage} alt="Empty State" />
      <div className="flex flex-col gap-1 mb-2 max-w-[240px]">
        <h2 className="text-lg font-semibold text-text-primary">{emptyStateProps.title}</h2>
        <p className="small-text">{emptyStateProps.description}</p>
      </div>
      {emptyStateProps.ctaText && emptyStateProps.onCtaClick && <Button onClick={() => emptyStateProps.onCtaClick?.()} text={emptyStateProps.ctaText} />}
    </div>
  );
};

export default EmptyState;
