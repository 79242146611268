interface FooterControlsProps {
  messageRole?: string;
  exerciseState?: string;
  children: React.ReactNode;
}

const FooterControls: React.FC<FooterControlsProps> = ({ messageRole, exerciseState, children }) => {
  const backgroundColorClass = exerciseState === 'correct' ? 'bg-bg-success'
    : exerciseState === 'wrong' ? 'bg-bg-error'
    : exerciseState === 'almost-correct' ? 'bg-bg-warning'
    : 'bg-bg-primary';

  const positionClass = (messageRole === 'book-translate') ? 'relative' : 'fixed';

  return (
    <div
      className={`${positionClass} ${backgroundColorClass} fixed bottom-0 left-0 z-10 flex justify-center 
        border-t border-border-secondary transition-colors duration-100 w-full md:h-24 py-4 md:py-0 layout-padding-x`}
    >
      <div className="max-w-[800px] flex flex-1 flex-col md:flex-row">
        {children}
      </div>
    </div>
  );
}

export default FooterControls;
