import NotFoundImage from '../../assets/not_found_robot.svg';
import { useNavigate } from 'react-router-dom';
import Button from '../baseComponents/Button';
import { MainLayout } from '../baseComponents/Layout';
import { useLanguage } from '../../languages/LanguageContext';

const NotFoundPage = () => {
  const navigate = useNavigate();
  const { getTextLangFrom } = useLanguage();

  return (
    <MainLayout hasNavbar={false}>
      <div className="flex items-center justify-center h-screen p-6">
        <div className="flex flex-col items-center gap-7">
          <img src={NotFoundImage} alt="Not Found" className="w-full max-w-[300px]" />
          <h1 className="text-4xl font-bold text-center md:text-5xl">
            {getTextLangFrom('NotFoundPage-title')}
            
          </h1>
          <p className="text-center text-text-tertiary text-lg">
            {getTextLangFrom('NotFoundPage-subtitle')}
          </p>
          <Button text={getTextLangFrom('NotFoundPage-cta')} variant='primary' size='xl' onClick={() => navigate('/')} />
        </div>
      </div>
    </MainLayout>
  );
};

export default NotFoundPage;
