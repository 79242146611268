import React from 'react';
import CloseIcon from '../../assets/x-close.svg';
import ModalPatternImg from '../../assets/modal-pattern.svg';
import Button from './Button';
import Stepper from './Stepper';
import { ModalStepper } from '../../types';
import { useAuth } from '../../authentification/AuthContext';

export interface ModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  confirmText: string;
  onConfirm: () => void;
  cancelText?: string;
  onCancel?: () => void;
  onClose?: () => void;
  message?: string;
  children?: React.ReactNode;
  size?: 'sm' | 'md' | 'lg';  
  isConfirmButtonDisabled?: boolean;
  modalStepper?: ModalStepper;
  modalHeight?: string;
  isLoading?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  setIsOpen,
  title,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  onClose,
  message,
  children,
  size = 'sm',
  isConfirmButtonDisabled = false,
  modalStepper,
  modalHeight = '',
  isLoading,
}) => {
  const { user } = useAuth();
  
  if (!isOpen) return null;

  // Size class mapping
  const sizeClasses = {
    sm: 'w-full sm:w-[480px]',
    md: 'w-full sm:w-[520px]',
    lg: 'w-full sm:w-[640px]',
  };

  return (
    <div className="overlay-page">
      <div className={`relative flex flex-col gap-4 p-4 items-center justify-center flex-1 ${modalHeight ? modalHeight : 'h-full'}`}>
        {modalStepper && <Stepper currentStep={modalStepper.currentStep} totalSteps={modalStepper.totalSteps} />}
        <div
          className={`relative bg-bg-primary rounded-xl shadow-xl overflow-hidden ${sizeClasses[size]}`}
        >
          {/* Modal background pattern */}
          <img
            src={ModalPatternImg}
            alt="Pattern"
            className={`absolute top-[-10px] left-[-24px] object-cover w-52 h-52 pointer-events-none z-0 opacity-10 ${user?.theme === 'light' ? 'filter invert' : ''}`}
          />
          <div className='flex flex-col flex-1 gap-2 text-left p-6 h-full'>
            {/* Modal Header */}
            <div className="flex justify-between mb-4 z-10">
              <div className="flex flex-col gap-2 flex-1">
                <h2 className="text-xl font-semibold">{title}</h2>
                {message && <p className="small-text">{message}</p>}
              </div>
              <Button variant="tertiary" iconOnly={true} onClick={() => (onClose ? onClose() : setIsOpen(false))} leadingIcon={CloseIcon} />
            </div>

            {/* Modal Body */}
            {children}

            {/* Modal Footer */}
            <div className="mt-auto pt-6 flex gap-3 justify-end sm:flex-row flex-col-reverse">
              {onCancel && cancelText && (
                <Button variant="secondary" onClick={onCancel} text={cancelText} disabled={isLoading} className="w-full sm:w-auto" />
              )}
              <Button onClick={onConfirm} text={confirmText} disabled={isConfirmButtonDisabled || isLoading} className="w-full sm:w-auto" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
