import CorrectIcon from '../../../assets/result-correct.svg';
import WrongIcon from '../../../assets/result-wrong.svg';
import AlmostCorrectIcon from '../../../assets/result-almost-correct.svg';
import { useLanguage } from '../../../languages/LanguageContext';
import { ExerciseState } from '../../../types';
import ExampleText from './ExampleDiv';

interface ResultsDisplayProps {
  lemmaLangTo?: string;
  exerciseState: ExerciseState;
  exampleLangTo?: string;
  messageRole?: string;
  pos?: string;
  lemmaLangFrom?: string;
}

export const ResultsDisplay: React.FC<ResultsDisplayProps> = ({
  lemmaLangTo,
  exampleLangTo,
  exerciseState,
  messageRole,
  pos,
  lemmaLangFrom
}) => {
  const { getTextLangFrom } = useLanguage();

  const getStatusIcon = () => {
    switch (exerciseState) {
      case 'correct':
        return CorrectIcon;
      case 'wrong':
        return WrongIcon;
      case 'almost-correct':
        return AlmostCorrectIcon;
      default:
        return '';
    }
  };

  const getResultTitleText = () => {
    switch (exerciseState) {
      case 'correct':
        return getTextLangFrom('word-correct');
      case 'wrong':
        if (messageRole === 'book-translate') {
          return getTextLangFrom('control-translate-title-wrong');
        } else {
          return getTextLangFrom('control-title-wrong');
        }
      case 'almost-correct':
        return getTextLangFrom('control-title-almost-correct');
      default:
        return '';
    }
  };

  // console.log("messageRole", messageRole);

  return (
    <div className="flex gap-5 items-center">
      {(exerciseState === 'correct' || exerciseState === 'almost-correct' || exerciseState === 'wrong') ? (
        <div className="flex flex-col md:flex-row gap-2 md:gap-5 pb-2 md:pb-0">
          <div className={`flex items-center gap-3 md:gap-4
              ${exerciseState === 'correct' ? 'text-text-success' : exerciseState === 'wrong' ? 'text-text-error' : 'text-text-warning'}`}
          >
            <img
              className="flex justify-center items-center rounded-full shadow-xs bg-bg-primary w-10 h-10 md:w-16 md:h-16 p-1 md:p-2"
              src={getStatusIcon()}
              alt="Status"
            />
            <p className={`block ${ messageRole !== 'book-translate' && 'md:hidden' } text-lg font-medium`}>{getResultTitleText()}</p>
          </div>
          <div className="flex flex-col gap-2 justify-center md:pb-0">
            <div className="flex gap-3 items-end">
              {lemmaLangTo && <p className="translation-solution-text">{lemmaLangTo}</p>}
              <div className="flex gap-2 items-center">
                <p className="small-text mb-0.5 md:mb-0">{pos}</p>
              </div>
            </div>
            <ExampleText example={exampleLangTo} className="mb-2 md:mb-0" />
          </div>
        </div>
      ) : (messageRole === 'book-translate' && lemmaLangFrom) ? (
        <div className="mb-3 md:mb-0 text-lg font-medium flex gap-2 items-center">
          {getTextLangFrom('word-translate')}{' '}
          <span className="text-text-brand">{lemmaLangFrom}</span>
          <span className="small-text mt-1">{pos}</span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
