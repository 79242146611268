import { BookExercise, Message, TranslationTooltip, User } from "../../../types";
import showTooltipOnTextSelection from "./onTextSelection";
import showTooltiponWordClick from "./onWordClick";

// Now use these functions where necessary
const onBookMouseUp = async (
  event: React.MouseEvent<HTMLSpanElement>,
  setTooltip: React.Dispatch<React.SetStateAction<TranslationTooltip>>,
  user: User | null,
  messages: Message[],
  getTextLangFrom: (key: string) => string,
  chatId: number,
  part?: BookExercise | string,
  hasClickedOnAWord?: boolean
) => {
  let tooltip: TranslationTooltip = {
    isVisible: false,
    bookExercise: undefined,
    googleTranslation: undefined,
    position: { top: undefined, bottom: undefined, left: undefined, right: undefined },
    userId: user?.id || 0,
    chatId: chatId,
    errorMessage: "",    
    isLoading: false,
  };

  const selection = window.getSelection();
  const isTextSelected = selection && selection.rangeCount > 0 && selection.toString().length > 0;

  // console.log("selection", selection, "part", part, "user", user);

  // First check if there's selected text and a user
  if (user && isTextSelected) {
    await showTooltipOnTextSelection(selection, setTooltip, messages, tooltip, user, getTextLangFrom);
  } 
  // If no text is selected, check if a word was clicked
  else if (!isTextSelected && part && user) {
    await showTooltiponWordClick(event, part, setTooltip, tooltip, user);
  } // any other case, hide the tooltip. See TranslationTooltip.tsx
};


export default onBookMouseUp;