import { ExerciseState } from '../../../types';

const getStyledGapText = (string1: string, string2: string, exerciseState: ExerciseState, isInputField: boolean = false) => {
  console.log('string1', string1, 'string2', string2, 'exerciseState', exerciseState, 'isInputField', isInputField);
  let idx1; // index of the first different character
  let idx2; // index of the last different character initialized 
  
  for (idx1 = 0; idx1 < string1.length; idx1++) {
    if (string1[idx1] !== string2[idx1]) {
      break;
    }
  }

  // search for the first different character string1 from the end of both strings
  for (idx2 = string1.length; idx2 > 0; idx2--) {
    const idx_string2 = idx2 - string1.length + string2.length;
    if (idx_string2 < 0 || string1[idx2 - 1] !== string2[idx_string2 - 1]) {
      break;
    }
  }

  const getColorClass = (exerciseState?: ExerciseState, isInputField?: boolean) => {
    if (isInputField && exerciseState === 'wrong') return 'text-text-error';
    if (isInputField && exerciseState === 'almost-correct') return 'text-text-warning';
    if (isInputField && exerciseState === 'correct') return 'text-text-success';
    if (!isInputField && exerciseState) return 'text-text-success';
    return 'text-text-primary'; // Default color
  };

  return (
    <div className="flex justify-center">
      {isInputField && string1 === string2 ? (
        <span className={getColorClass(exerciseState, isInputField)}>
          {string1.slice(0, idx1)} 
        </span>
      ) : (
        <span className="whitespace-pre">
          {string1.slice(0, idx1)}
        </span>
      )}
      <span className={`whitespace-pre ${getColorClass(exerciseState, isInputField)}`}>
        {string1.slice(idx1, idx2)}
      </span>
      {string1 !== string2 && (
        <span className="whitespace-pre">
          {string1.slice(idx2)}
        </span>
      )}
    </div>
  );
};

export default getStyledGapText;
