import React, { useState, useCallback } from 'react';
import LoadingAnimation from '../baseComponents/LoadingAnimation';
import { uploadBook } from '../../api/Books';
import { useLanguage } from '../../languages/LanguageContext';
import { useAuth } from '../../authentification/AuthContext';
import { useNavigate } from 'react-router-dom';
import { MainLayout } from '../baseComponents/Layout';
import DropArea from './DropArea';
import { Language } from '../../types';

// Sample links for each language
const languageLinks: { [key in Language]: { name: string; url: string }[] } = {
  en: [
    { name: 'Project Gutenberg', url: 'https://www.gutenberg.org' },
    { name: 'Internet Archive', url: 'https://archive.org/' },
  ],
  es: [
    { name: 'Project Gutenberg', url: 'https://www.gutenberg.org' },
    { name: 'Internet Archive', url: 'https://archive.org/' },
    { name: 'Biblioteca Virtual Miguel de Cervantes', url: 'https://www.cervantesvirtual.com/obras/serie/epubs_bvmc/' },
  ],
  de: [
    { name: 'Project Gutenberg', url: 'https://www.gutenberg.org' },
    { name: 'Internet Archive', url: 'https://archive.org/' },
  ],
  it: [
    { name: 'Liber Liber', url: 'https://liberliber.it/opere/libri/argomento/epub/' },
    { name: 'Project Gutenberg', url: 'https://www.gutenberg.org' },
    { name: 'Internet Archive', url: 'https://archive.org/' },
  ],
  fr: [
    { name: 'Project Gutenberg', url: 'https://www.gutenberg.org' },
    { name: 'Internet Archive', url: 'https://archive.org/' },
  ],
};

const BookUploadPage: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getTextLangFrom } = useLanguage();
  const { user } = useAuth();

  const handleFileUpload = useCallback(async (file: File) => {
    if (!user) {
      return;
    }

    if (file && file.type === 'application/epub+zip') {
      setIsLoading(true);
      try {
        const Book = await uploadBook(file, user.id, user.langTo);
        navigate('/book-edit', { state: { Book } });
      } catch (error) {
        console.error("Error uploading the file:", error);
        alert("File upload failed. Probably the file format is not supported, the file is too large, or the book is already in your library.");
      } finally {
        setIsLoading(false);
      }
    } else {
      alert("Please upload a valid .epub file.");
    }
  }, [user, navigate]);

  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files?.[0];
    if (file) {
      handleFileUpload(file);
    }
  }, [handleFileUpload]);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  return (
    <MainLayout
      hasNavbar={true}
      title={getTextLangFrom('BookUploadPage-title')}
    >
      <DropArea 
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onClick={() => document.getElementById('droparea-upload')?.click()}
        handleFileChange={handleFileChange}
        isLoading={isLoading}
        getTextLangFrom={getTextLangFrom}
        supportedFormats='.epub'
      />
      <div className="flex flex-col gap-5 items-start">
      {user && languageLinks[user.langTo].length !== 0 && (
        <p className="mb-1.5 text-sm text-text-secondary">
          {getTextLangFrom('BookUploadPage-links-title')} {getTextLangFrom('language-' + user.langTo)}? Check out {' '}
            {languageLinks[user.langTo].map((link, index) => (
              <span key={link.url}>
                <a 
                  href={link.url} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className='text-sm font-medium text-text-brand hover:text-text-primary'>
                  {link.name}
                </a>
                {index < languageLinks[user.langTo].length - 1 && ', '}
              </span>
          ))}
        .
      </p>
      )}
    </div>
    <LoadingAnimation isLoading={isLoading} />
  </MainLayout>
);

}

export default BookUploadPage;