export interface BreadcrumbItem {
  icon?: string;
  text?: string;
  link?: string;
}

export interface ModalStepper {
  currentStep: number;
  totalSteps: number;
}

export interface EmptyStateProps {
  title: string;
  description?: string;
  ctaText?: string;
  onCtaClick?: () => void;
}

/* App-specific types */

export type  MessageRole = 'user' | 'agent' | 'error' | 'warning' | 'title' | 'intro' | 'book-translate';

export type ExerciseType = 'book-chapter-review' | 'book-general-review' | 'vocabulary' | 'next-chapter';

export type GameMode = 'resume-book' | 'start-new-book' | 'book-mode' | 'multiple-choice-mode' | 'flashcard-mode' | 'write-mode';

export type GameUrl = '/library' | '/book-mode' | '/multiple-choice-mode' | '/flashcard-mode' | '/write-mode';

export type Language = 'en' | 'it' | 'fr' | 'de' | 'es';

export type CefrLevel = 'A1' | 'A2' | 'B1' | 'B2' | 'C1' | 'C2';

export type Theme = 'light' | 'dark' | 'dark-dusk';

export type ButtonVariants = 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'error' | 'menuDesktop' | 'menuMobile';

export type UserStatusesList = 'first-time-user' | 'active-user' | 'waitlisted' | 'server-error';

export function gameModeToUrl(gameMode: GameMode): GameUrl {
  switch (gameMode) {
    case 'book-mode':
      return '/book-mode';
    case 'multiple-choice-mode':
      return '/multiple-choice-mode';
    case 'flashcard-mode':
      return '/flashcard-mode';
    case 'write-mode':
      return '/write-mode';
    default:
      console.error('Invalid game mode');
      return '/library';
  }
}

export function getButtonVariantForExerciseState(state: ExerciseState): ButtonVariants {
  switch (state) {
    case 'correct':
      return 'success';
    case 'almost-correct':
      return 'warning';
    case 'wrong':
      return 'error';
    default:
      return 'primary';
  }
}

export interface Message {
  text: string;
  message_id?: number;
  messageRole: MessageRole;
  userId?: number;
  chatId?: number;
  agentId: number;
  sessionProgress?: number;
  bookExercises?: (string | BookExercise)[];
}

export interface UserStatus {
  status: UserStatusesList;
  waitlistPosition?: number;
}

export interface User {
  id: number;
  username: string;
  langFrom: Language;
  langTo: Language;
  cefrLevel: CefrLevel;
  theme: Theme;
  voiceOn: boolean;
  voiceSpeed: number;
  voiceName: 'Emma' | 'James' | 'Sophia' | 'Michael';
  status: UserStatusesList;
  numberStreakDays: number;
  last7dStreakDays: boolean[];
  isTesting?: boolean;
}


export interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  loginWithGoogle: (email: string) => Promise<void>;
  logout: () => void;
  updateLocalUser: (updatedUser: User) => void;
  updateLocalAndDbUser: (updatedUser: User) => Promise<void>;
  checkUserStatus: (email: string) => Promise<UserStatus>;
}

export interface GameState {
  gameMode: GameMode;
  nextExerciseType: ExerciseType;
  cards?: Card[];
  accuracy?: number;
  gameDuration?: number;
  chatId?: number;
  numberCardsToReview?: number;
}

export interface NavigationState {
  books: Book[];
  last7dStreakDays: boolean[];
  numberStreakDays: number;
  wordTableData: Word[];
  profileData: ProfileData;
}

export interface BookState {
  initialMessages: Message[];
  chapterMessages: Message[];
  bookExercises: BookExercise[];
  chatId?: number;
  chapterNumber?: number;
}

export type TooltipTag = "noTag" | "newWord" | "hardWord" | "masteredWord" | "blacklistedWord";

export type GameTag = "new" | "review";

export interface Card {
  translationId: number;
  lemmaLangFrom: string;
  exampleLangFrom?: string;
  lemmaLangTo: string;
  exampleLangTo?: string;
  score?: boolean;
  tag?: GameTag;
  pos?: string;
  otherLemmasLangTo?: string[];
  userResponse?: string;
}

export interface ExerciseRecord {
  translationId: number;
  score: boolean;
  userResponse?: string;
}

export interface BookExercise {
  wordLangToId: number;
  translationId: number;
  lastSeen?: string;
  netCorrectScore?: number;
  isInBlackList?: boolean;
  cefrLevel?: CefrLevel;
  pos: string;
  lemmaLangFrom: string;
  genderLangFrom: string;
  numberLangFrom: string;
  pluralLangFrom?: string;
  otherLemmasLangFrom?: string[];
  exampleLangFrom?: string;
  lemmaLangTo: string;
  genderLangTo: string;
  numberLangTo: string;
  pluralLangTo?: string;
  exampleLangTo?: string;
  solution: string;
  userResponse: string;
  score?: boolean;
  exerciseState: ExerciseState;
  isExercise?: boolean;
  tag?: TooltipTag;
}


export interface TranslationTooltip {
  userId: number;
  bookExercise: BookExercise | undefined;
  googleTranslation: string | undefined;
  isVisible: boolean;
  position: {
    top: number | undefined;
    bottom: number | undefined;
    left: number | undefined;
    right: number | undefined;
  };
  chatId: number;
  errorMessage?: string;
  isLoading?: boolean;
}

export type Book = {
  id: number;
  chatId?: number;
  title: string;
  author: string;
  progress: number;
  coverImage?: any;
  uploaderId?: number;
  lastAccessed?: string;
};


export type ExerciseState = "correct" | "almost-correct" | "wrong" | "unanswered" | "loading";


export type ChapterForNavigation = {
  title: string;
  chapters: {
    numberWithinOriginalChapter: number;
    number: number;
    text: string;
  }[];
};


export interface Word {
  lemmaLangFrom: string;
  lemmaLangTo: string;
  lastSeen: string;
  score: number;
}

export interface ProfileData {
  numberWordsLearned: number;
  pagesReadPerDay: number[];
  words: Word[];
}

export interface OnboardingOption {
  code: string;
  text: string;
  icon?: string;
}

export interface OnboardingData {
  language?: Language;
  cefrLevel?: Extract<CefrLevel, 'A1' | 'A2' | 'B1' | 'B2'>
}
