import SolutionTextWrapper from './SolutionTextWrapper';
import MessageDiv from './MessageDivStyle';
import { BookExercise, Message, TranslationTooltip, User } from '../../types';
import { useLanguage } from '../../languages/LanguageContext';
import onBookMouseUp from './TranslationTooltip/onBookMouseUp';

interface MessageContentProps {
  index: number;
  activeMessage: Message;
  messages: Message[];
  user: User | null;
  tooltip: TranslationTooltip;
  setTooltip: React.Dispatch<React.SetStateAction<TranslationTooltip>>,
  messageRole: string;
  handleTranslateInputFocus: (index: number) => void;
  handleTranslateInputBlur: () => void;
  inputRefs: any;
  chatId: number;
}

const MessageContent = ({ 
  index, activeMessage, messages, user, setTooltip,
  messageRole, handleTranslateInputFocus, handleTranslateInputBlur, inputRefs, chatId
 }: MessageContentProps) => {
  const { getTextLangFrom } = useLanguage();
  const isLastMessage = index === messages.length - 1;
  
  const handleWordClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, part: BookExercise | string) => {
    event.preventDefault();
    event.stopPropagation();
    onBookMouseUp(event, setTooltip, user, messages, getTextLangFrom, chatId, part, true);
  }
    
  const ClickableTextSpan = ({ text }: { text: string }) => {
    const words = text.split(/(\s+|[.,!?;:(){}[\]<>]+)/);

    return (
      <p className="inline">
        {words.map((word, wordIndex) => (
          word.trim().length > 0 && !/[.,!?;:]/.test(word) ? (
            <span className="clickable-span span-with-click" key={wordIndex} onClick={(event) => handleWordClick(event, word)}>
              {word}
            </span>
          ) : (
            word
          )
        ))}
      </p>
    );
  };

  return (
    <MessageDiv
      key={index}
      messageRole={activeMessage.messageRole}
      data-tip
      data-for="tooltip"
    >
      <div>
        { activeMessage.bookExercises?.map((part, index) => ( typeof part === 'string' ? (
            <ClickableTextSpan text={part} key={index} />
          ) : isLastMessage && part.isExercise === true ? ( messageRole === 'book-translate' && !part.userResponse ? (
              <input
                key={index}
                type="text"
                placeholder={part.lemmaLangFrom}
                onFocus={() => handleTranslateInputFocus(index)}
                onBlur={handleTranslateInputBlur}
                ref={(el) => inputRefs.current[index] = el}
                autoCapitalize="none"        // Disable auto-capitalization
                inputMode="text"             // Specify text input mode
                autoComplete="off"           // Disable autocomplete suggestions
                autoCorrect="off"            // Disable autocorrect
                spellCheck="false"           // Disable spell check
                pattern=".*"                 // Prevent some auto-suggestions
                enterKeyHint="go"            // Customize Enter key behavior
                className={`px-2 border border-border-primary rounded-lg text-text-primary font-medium shadow-xs bg-bg-primary w-[120px] 
                  placeholder:text-text-placeholder placeholder:font-normal
                  focus:border-border-brand focus:outline-none focus:shadow-ring-brand-xs 
                  disabled:text-text-disabled disabled:border-border-disabled disabled:cursor-not-allowed`}
              />
            ) : (messageRole === 'book-translate' && part.userResponse) ? (
              <SolutionTextWrapper 
                key={index}
                part={part}
                theme={user?.theme || 'light'} 
                handleShowTooltip={(event, part) => handleWordClick(event, part)}
              />
            ) : (
              <span key={index} className="span-with-click" onClick={(event) => handleWordClick(event, part)}>
                {part.solution}
              </span>
            )
          ) : // isLastMessage is false or (isLastMessage is true and isExercise is false)
          <span key={index} className="span-with-click" onClick={(event) => handleWordClick(event, part)}>
            {part.solution}
          </span>
        ))}
        { activeMessage.bookExercises?.length === 0 && activeMessage.text }
      </div>
    </MessageDiv>
  );
};

export default MessageContent;
