import React, { useEffect, useState } from 'react';
import { useLanguage } from '../../languages/LanguageContext';
import HorizontalMenu from '../baseComponents/HorizontalMenu';
import WordsTable from './WordsTable';
import { NavigationState, ProfileData, Word } from '../../types';
import { fetchProfilePageData } from '../../api/Profile';
import { useAuth } from '../../authentification/AuthContext';
import { StatsTiles } from './StatsTiles';
import { MainLayout } from '../baseComponents/Layout';
import { Loader } from '../baseComponents/Loader';
import { useLocation } from 'react-router-dom';


const ProfilePage: React.FC = () => {
  const { getTextLangFrom } = useLanguage();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [wordTableData, setWordTableData] = useState<Word[]>([]);
  const [profileData, setProfileData] = useState<ProfileData>();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useLocation();

  const tabs = [
    { text: getTextLangFrom('word-statistics'), handleClick: () => setSelectedTab(0) },
    { text: getTextLangFrom('word-Words'), handleClick: () => setSelectedTab(1) },
  ];

  useEffect(() => {
    if (user) {
      const navigationState = location?.state?.navigationState as NavigationState;
      const hasDataInLocationState = navigationState && (navigationState.wordTableData !== undefined && navigationState.profileData !== undefined);
      if (hasDataInLocationState) {
        setWordTableData(navigationState.wordTableData);
        setProfileData(navigationState.profileData);
      } else {
        const fetchDataAsync = async () => {
          setIsLoading(true);
          try {
            const fetchedProfileData = await fetchProfilePageData(user.id);
            setWordTableData(fetchedProfileData.words);
            setProfileData(fetchedProfileData);

            location.state = {
              ...location.state,
              navigationState: {
                ...location.state?.navigationState,
                wordTableData: fetchedProfileData.words,
                profileData: fetchedProfileData,
              } as NavigationState,
            };
          } catch (error) {
            console.error('Error fetching data:', error);
          } finally {
            setIsLoading(false);
          }
        };

        fetchDataAsync();
      }
    }
  }, [user, location]);


  return (
    <MainLayout
      hasNavbar={true}
      isFullHeight={true}
      title={getTextLangFrom('word-profile')}
    >
      <HorizontalMenu tabs={tabs} />
      {isLoading ? (
        <Loader />
      ) :  selectedTab === 0 ? (
        <StatsTiles profileData={profileData} />
      ) : (
        <WordsTable wordTableData={wordTableData} />
      )}
    </MainLayout>
  );

};

export default ProfilePage;
