import React from 'react';
import { ExerciseState } from '../../../types';

interface MultipleChoiceOptionsProps {
  options: string[];
  selectedAnswer: string | null;
  correctAnswer: string;
  exerciseState: ExerciseState;
  onOptionClick: (option: string) => void;
}

const MultipleChoiceOptions: React.FC<MultipleChoiceOptionsProps> = ({
  options,
  selectedAnswer,
  correctAnswer,
  exerciseState,
  onOptionClick,
}) => {
  return (
    <div
      className={`flex ${options.length <= 4 ? 'flex-col' : 'flex-wrap'} gap-4 min-h-[270px] w-full max-w-[600px] mx-auto`}
    >
      {options.map((option, index) => (
        <button
          key={index}
          onClick={() => onOptionClick(option)}
          className={`multiple-choice-button-base 
            ${selectedAnswer ? 'cursor-not-allowed' : 'multiple-choice-button-hover'} 
            ${correctAnswer === option && selectedAnswer ? ('bg-bg-success border-border-success text-text-success'
            ) : (selectedAnswer === option && exerciseState === 'wrong' ? ( 'bg-bg-error border-border-error text-text-error'
            ) : 'multiple-choice-button-base-colors')}
          `}
          disabled={selectedAnswer !== null}
        >
          <div className="w-8 h-8 flex items-center justify-center text-base font-semibold rounded-full bg-bg-tertiary opacity-50">
            {index + 1}
          </div>
          {option}
        </button>
      ))}
    </div>
  );
};

export default MultipleChoiceOptions;
