// FlashCardPage.tsx

import React from 'react';
import OnboardingLayout from './OnboardingLayout';
import { useLanguage } from '../../languages/LanguageContext';
import Fireworks from '../baseComponents/FireworksAnimation';
import CelebratingBotImage from '../../assets/robot-celebrating-success.svg';

const OnboardingWelcome: React.FC = () => {
  const { getTextLangFrom } = useLanguage();

  return (
    <OnboardingLayout step={1} isFullHeight={true}>
      <Fireworks />
      <div className="flex-1 max-h-[300px] w-full" style={{ backgroundImage: `url(${CelebratingBotImage})`, 
          backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} />
      <div className="flex flex-col gap-4">
        <h1 className="text-center text-4xl font-semibold md:text-4xl mb-2">{getTextLangFrom('OnboardingPage-welcome-title')}</h1>
        <p className="text-center text-lg text-text-secondary md:text-xl">{getTextLangFrom('OnboardingPage-welcome-text')}</p>
      </div>
    </OnboardingLayout>
  );
};

export default OnboardingWelcome;
