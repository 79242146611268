import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Message, BookExercise, TranslationTooltip, ExerciseState } from '../../types';
import { useLanguage } from '../../languages/LanguageContext';
import { useLocation, useNavigate } from 'react-router-dom';
// import { saveBookExerciseResults } from '../../api/Books';
import BookFooterControls from './BookFooterControls';
import MessageContent from './MessageContent';
import TranslationTooltipDiv from './TranslationTooltip/TranslationTooltip';
import onBookMouseUp from './TranslationTooltip/onBookMouseUp';
import evaluateExerciseState from '../../utils/exerciseState';
import { useAuth } from '../../authentification/AuthContext';
import handleTerminateChapter from './bookUtils/terminateChapter';
import Modal from '../baseComponents/Modal';

interface BookExerciseDivProps {
  setSessionProgress: React.Dispatch<React.SetStateAction<number>>;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}


const BookExerciseDiv: React.FC<BookExerciseDivProps> = ({ setSessionProgress, isModalOpen, setIsModalOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const [tooltip, setTooltip] = useState({} as TranslationTooltip);
  const [lastMessageExParts, setLastMessageExParts] = useState<(string | BookExercise)[]>([]);
  const { getTextLangFrom } = useLanguage();
  const [focusedExerciseIndex, setFocusedExerciseIndex] = useState<number>(-1);
  const [messageRole, setMessageRole] = useState<string>('');
  const [buttonText, setButtonText] = useState(getTextLangFrom('word-continue'));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [exerciseState, setExerciseState] = useState<ExerciseState>("unanswered");
  const [learnWordPreferences, setLearnWordPreferences] = useState<{ translationId: number, isToLearn: boolean }[]>([]);
  const { user } = useAuth();
  const [initialTime, setInitialTime] = useState<Date>(new Date());

  useEffect(() => {
    if (!user || !location.state.gameData || !location.state.gameData.chatId) {
      navigate('/library');
    } else {
      setInitialTime(new Date());
    }
  }, [user, location.state, navigate]);

  const chapterMessages = location.state.gameData.chapterMessages;
  const [currentMessages, setCurrentMessages] = useState<Message[]>(location.state.gameData.initialMessages);
  const numberIntroMessages = location.state.gameData.initialMessages.length - 1;

  const { chatId } = location.state.gameData;
  

  const focusNextExercise = useCallback((bookExercises: (string | BookExercise)[]) => {
    // Remove focus from current input
    setFocusedExerciseIndex(-2);
    setExerciseState("unanswered");
  
    // Find next input
    for (let i = 0; i < bookExercises.length; i++) {
      if (typeof bookExercises[i] !== 'string' && (bookExercises[i] as BookExercise).isExercise && !(bookExercises[i] as BookExercise).userResponse) {
        setFocusedExerciseIndex(i);
        if (messageRole === 'book-translate') {
          const inputRef = inputRefs.current[i];
          inputRef?.focus();
        }
        return i;
      }
    }
    return -2;
  }, [messageRole]);

  const setMessagesAndScrollDown = useCallback((newMessages: Message[]) => {
    setCurrentMessages(newMessages);
    setTimeout(() => {
      messagesContainerRef.current?.scrollTo(0, messagesContainerRef.current?.scrollHeight);
    }, 100);
  }, [setCurrentMessages]);

  useEffect(() => {
    // Parse the last message to get the exercise bookExercises
    if (currentMessages.length !== 0) {
      setLastMessageExParts(currentMessages[currentMessages.length - 1].bookExercises || []);
      const lastMessageText = currentMessages[currentMessages.length - 1];
      setMessageRole(lastMessageText.messageRole);
      setMessagesAndScrollDown(currentMessages);
      const index = focusNextExercise(lastMessageText?.bookExercises || []);
      if (lastMessageText.messageRole === 'book-translate') {
        // Set focus on index
        const inputRef = inputRefs.current[index];
        inputRef?.focus();
      }
    }
  }, [currentMessages, setMessagesAndScrollDown, focusNextExercise]);

  const handleClick = useCallback(async (userResponse: string) => {
    let inputRef = null as HTMLInputElement | null;

    if (user && focusedExerciseIndex === -2) {
      // End of exercise. Get the next messages
      setExerciseState("loading");
      setFocusedExerciseIndex(-1);

      if (currentMessages.length - numberIntroMessages === chapterMessages.length) {
        handleTerminateChapter(navigate, user.id, chatId, chapterMessages, initialTime, true);
      } else {
        setExerciseState("unanswered");
        setSessionProgress((currentMessages.length - numberIntroMessages) / chapterMessages.length * 100);
        setMessagesAndScrollDown([...currentMessages, chapterMessages[currentMessages.length - numberIntroMessages]]);
      }
    } else {
      if (focusedExerciseIndex >= 0) {
        setExerciseState("loading");
        // console.log('Focused index', focusedExerciseIndex);
        inputRef = inputRefs.current[focusedExerciseIndex];
        userResponse = inputRef?.value || '';
        const exState = evaluateExerciseState(userResponse, (lastMessageExParts[focusedExerciseIndex] as BookExercise).solution, 'write-mode');
        setExerciseState(exState);
        (lastMessageExParts[focusedExerciseIndex] as BookExercise).exerciseState = exState;
        // console.log('Exercise state', (lastMessageExParts[focusedExerciseIndex] as BookExercise).exerciseState);
        setButtonText(getTextLangFrom('word-continue'));
      } else {
        const index = focusNextExercise(lastMessageExParts);
        if (index !== undefined) {
          inputRef = inputRefs.current[index];
        }
        return;
      }
    }

    const newBookExercises = [...lastMessageExParts];
    const part = newBookExercises[focusedExerciseIndex];
    if (typeof part !== 'string') {
      newBookExercises[focusedExerciseIndex] = { ...part, userResponse: userResponse };
    }

    currentMessages[currentMessages.length - 1].bookExercises = newBookExercises;
    setLastMessageExParts(newBookExercises);

    if (userResponse !== '') {
      setFocusedExerciseIndex(-1);
    } else {
      focusNextExercise(newBookExercises);
    }

  }, [currentMessages, chapterMessages, lastMessageExParts, focusNextExercise, focusedExerciseIndex, numberIntroMessages, user,
     setMessagesAndScrollDown, navigate, chatId, initialTime, setSessionProgress, getTextLangFrom]);
  
  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      if (exerciseState !==  "loading") {
        if (event.key === 'Enter') {
          event.preventDefault();
          if (focusedExerciseIndex < 0){
            await handleClick('');
          } else {
            await handleClick('');
          }
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
  
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [exerciseState, handleClick, focusedExerciseIndex]);

  const handleTranslateInputFocus = (index: number) => {
    setExerciseState("unanswered");
    setButtonText(getTextLangFrom('word-check'));
    setFocusedExerciseIndex(index);  
  };

  const handleTranslateInputBlur = () => {
    setButtonText(getTextLangFrom('word-continue'));
  };

  const handleOnMouseUp = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const target = event.target as HTMLElement;
    // console.log('Clicked on', target);
    const selection = window.getSelection();
    const isTextSelected = selection && selection.rangeCount > 0 && selection.toString().length > 0;

    if ( chatId && (isTextSelected || !(target instanceof HTMLSpanElement && target.classList.contains('clickable-span')))) {
      onBookMouseUp(event, setTooltip, user, currentMessages, getTextLangFrom, chatId);
    } 
  }

  return user && (
    <>
      <div className="chat-wrapper"  onMouseUp={handleOnMouseUp} >
        <div className="chat-inner-wrapper">
          <div className="chat-scroll-wrapper" ref={messagesContainerRef}>
            <div className="chat-scroll-inner-div">
              {currentMessages.map((message, index) => (
                <MessageContent
                  key={index}
                  index={index}
                  activeMessage={message}
                  messages={currentMessages}
                  user={user}
                  tooltip={tooltip}
                  setTooltip={setTooltip}
                  messageRole={messageRole}
                  handleTranslateInputFocus={handleTranslateInputFocus}
                  handleTranslateInputBlur={handleTranslateInputBlur}
                  inputRefs={inputRefs}
                  chatId={chatId}
                />
              ))}
              <div><div className="w-full h-1"></div></div> {/* Spacer */}
              <TranslationTooltipDiv tooltip={tooltip} setTooltip={setTooltip} learnWordPreferences={learnWordPreferences} setLearnWordPreferences={setLearnWordPreferences} />
            </div>
          </div>
        </div>
      </div>
      <BookFooterControls
        bookExercises={lastMessageExParts}
        exerciseState={exerciseState}
        focusedExerciseIndex={focusedExerciseIndex}
        messageRole={messageRole}
        buttonText={buttonText}
        handleClick={handleClick}
        getTextLangFrom={getTextLangFrom}
      />
      <Modal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={getTextLangFrom('modal-interrupt-header')}
        message={getTextLangFrom('modal-interrupt-reading-description')}
        confirmText={getTextLangFrom('word-resume')}
        cancelText={getTextLangFrom('word-leave')}
        onConfirm={() => setIsModalOpen(false)} 
        onCancel={() => handleTerminateChapter(navigate, user.id, chatId, chapterMessages, initialTime, false)}
      />
    </>
  );
};


export default BookExerciseDiv;