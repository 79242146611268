import React from 'react';
import { getGoogleTranslation } from "../../../api/Messages";
import { BookExercise, TranslationTooltip, User } from "../../../types";
import setTooltipPosition from './tooltipPosition';

const showTooltiponWordClick = async (
  event: React.MouseEvent<HTMLSpanElement>,
  part: BookExercise | string,
  setTooltip: React.Dispatch<React.SetStateAction<TranslationTooltip>>,
  tooltip: TranslationTooltip,
  user: User,
) => {
  tooltip = setTooltipPosition(tooltip, undefined, event);

  if (typeof part === 'string') {
    setTooltip({ ...tooltip, isVisible: true, isLoading: true });
    const googleTranslation = await getGoogleTranslation(part, user.langTo, user.langFrom);
    if (googleTranslation) {
      // console.log('googleTranslation', googleTranslation);
      setTooltip({ ...tooltip, googleTranslation: googleTranslation, isLoading: false, isVisible: true });
    } else {
      setTooltip({ ...tooltip, errorMessage: "Timeout error", isLoading: false, isVisible: true });
    }
  } else {
    tooltip.bookExercise = part; 
    setTooltip({ ...tooltip, isVisible: true });
  }
}

export default showTooltiponWordClick;