import React, { useEffect, useState } from 'react';
import { LanguageProvider } from './languages/LanguageContext';
import ExercisePage from './components/exercises/ExercisePage';
import FlashCardPage from './components/games/FlashCardPage/FlashCardPage';
import MultiplechoicePage from './components/games/MultipleChiocePage/MultipleChoicePage';
import ProfilePage from './components/profile/ProfilePage';
import WritePage from './components/games/WritePage/WritePage';
import LoginPage from './components/login/LoginPage';
import WaitlistPage from './components/login/WaitlistPage';
import EndGameStatsPage from './components/endGame/EndGameStatsPage';
import EndChapterPage from './components/endGame/EndChapterPage';
import NotFoundPage from './components/notFound/NotFoundPage';
import BookUploadPage from './components/library/BookUploadPage';
import EndGameStreakPage from './components/endGame/EndGameStreakPage';
import OnboardingWelcome from './components/onboarding/OnboardingWelcome';
import OnboardingLanguage from './components/onboarding/OnboardingLanguage';
import BookPage from './components/book/BookPage';
import OnboardingLevel from './components/onboarding/OnboardingLevel';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './authentification/AuthContext';
import PrivateRoute from './authentification/PrivateRoute';
import * as Sentry from "@sentry/react";
import LibraryPage from './components/library/LibraryPage';
import SettingsPage from './components/settings/SettingsPage';
import { GoogleOAuthProvider } from '@react-oauth/google';
import BookEditPage from './components/library/BookEditPage';
import { ThemeProvider } from './styles/ThemeContext';
import './styles/colors.css';
import './styles/styles.css';
import './styles/stylesApp.css';
import fetchValidClientId from './authentification/clientId';
import PrivacyPolicyPage from './website/TextPages/PrivacyPolicyPage';


const AppContent: React.FC = () => {

  useEffect(() => {
    // Enforce overflow hidden on body to disable scrolling
    document.body.style.overflow = 'hidden';
    return () => {
      // Clean up by resetting overflow when component unmounts (if needed)
      document.body.style.overflow = '';
    };
  }, []);
  

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/library" />} />
      {/* <Route path="/" element={<HomePage />} /> */}

      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<LoginPage />} />
      <Route path="/waitlist" element={<WaitlistPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route element={<PrivateRoute />}>
        <Route path='/library' element={<LibraryPage />} />
        <Route path='/onboarding-welcome' element={<OnboardingWelcome />} />
        <Route path='/onboarding-language' element={<OnboardingLanguage />} />
        <Route path='/onboarding-level' element={<OnboardingLevel />} />
        <Route path="/exercises" element={<ExercisePage />} />
        <Route path="/flashcard-mode" element={<FlashCardPage />} />
        <Route path="/multiple-choice-mode" element={<MultiplechoicePage />} />
        <Route path="/write-mode" element={<WritePage />} />
        <Route path="/book-mode" element={<BookPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/endgame" element={<EndGameStatsPage />} />
        <Route path="/streak-extension" element={<EndGameStreakPage />} />
        <Route path="/book-review-exercises" element={<EndChapterPage />} />
        <Route path="/book-upload" element={<BookUploadPage />} />
        <Route path="/book-edit" element={<BookEditPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

const App: React.FC = () => {
  const [clientId, setClientId] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchClientId = async () => {
      const client_id = await fetchValidClientId();
      setClientId(client_id);
    };

    fetchClientId();
  }, []);

  return clientId ? (
    <GoogleOAuthProvider clientId={clientId}>
      <AuthProvider>
        <Router>
          <LanguageProvider>
            <ThemeProvider>
              <AppContent />
            </ThemeProvider>
          </LanguageProvider>
        </Router>
      </AuthProvider>
    </GoogleOAuthProvider>
  ) : null;
};

export default App;
