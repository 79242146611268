import React from 'react';
import { useLanguage } from '../../languages/LanguageContext';
import formatDateForClient from '../../utils/formatDate';

export const CellScore: React.FC<{ score: number }> = ({ score }) => {
    return (
        <div className="flex items-end gap-0.5 h-full">
            <div className={`w-2 rounded-sm h-3 ${score > -1 ? 'bg-bg-brand' : 'bg-bg-tertiary'}`} />
            <div className={`w-2 rounded-sm h-5 ${score > 0 ? 'bg-bg-brand' : 'bg-bg-tertiary'}`} />
            <div className={`w-2 rounded-sm h-7 ${score > 1 ? 'bg-bg-brand' : 'bg-bg-tertiary'}`} />
        </div>
    );
};


export const CellTranslation: React.FC<{ lemmaLangFrom: string, lemmaLangTo: string }> = ({ lemmaLangFrom, lemmaLangTo }) => {
    return (
        <div className="flex flex-col">
            <span className="text-primary font-semibold">{lemmaLangTo}</span>
            <p className="small-text">{lemmaLangFrom}</p>
        </div>
    );
}

export const CellLastStudied: React.FC<{ lastSeen: string }> = ({ lastSeen }) => {
    const lastSeenDate = new Date(lastSeen);
    const { getTextLangFrom } = useLanguage();
    const formattedDate = formatDateForClient(lastSeenDate, getTextLangFrom);

    return (
        <p className="small-text hidden sm:block">{formattedDate}</p>
    );
};
