import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ChevronRightWhite from '../../assets/chevron-right-white.svg';
import FooterControls from '../baseComponents/FooterControls';
import { useLanguage } from '../../languages/LanguageContext';
import handleStartVocabularyGame from '../../utils/startVocabularyGame';
import { useAuth } from '../../authentification/AuthContext';
import LearnImage from '../../assets/listen-mode.svg';
import RefreshImage from '../../assets/home-multiple-choice.svg';
import Button from '../baseComponents/Button';
import EndGameLayout from './EndGameLayout';
import ChevronLeftSecondary from '../../assets/chevron-left-tertiary.svg';
import { GameState } from '../../types';
import handleNewChapter from '../book/bookUtils/startChapter';

const EndChapterPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!location?.state?.gameState) {
            navigate('/library');
            return;
        }
    }, [location, navigate]);

    const { getTextLangFrom, getTextLangTo } = useLanguage();
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { nextExerciseType, chatId, cards, numberCardsToReview } = location.state.gameState as GameState;

    const exercises = [
        {   
            type: "book-chapter-review",
            title: getTextLangFrom('EndChapterPage-chapter-review-title'),
            description: getTextLangFrom('EndChapterPage-chapter-review-description'),
            numberOfWords: numberCardsToReview + " " + getTextLangFrom('word-words'),
            imgSrc: LearnImage,
            imgAlt: "Image"
        },
        {
            type: "book-general-review",
            title: getTextLangFrom('EndChapterPage-general-review-title'),
            description: getTextLangFrom('EndChapterPage-general-review-description'),
            numberOfWords: 10 + " " + getTextLangFrom('word-words'),
            imgSrc: RefreshImage,
            imgAlt: "Image"
        }
    ];

    const startVocabularyGame = useCallback(() => {
        if (user && nextExerciseType === 'book-chapter-review') {
            handleStartVocabularyGame("write-mode", nextExerciseType, user, navigate, setIsLoading, chatId, cards, numberCardsToReview);
        } else if (user && nextExerciseType === 'book-general-review') {
            handleStartVocabularyGame("multiple-choice-mode", nextExerciseType, user, navigate, setIsLoading, chatId);
        }
    }, [user, nextExerciseType, navigate, chatId, cards]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter' || event.key === 'ArrowRight') {
                startVocabularyGame();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [startVocabularyGame]);

    return user && (
        <EndGameLayout
            title={getTextLangFrom('EndChapterPage-title')}
            isLoading={isLoading}
        >
            <div className="flex flex-col max-w-lg gap-8 md:gap-12 mt-12">
                {exercises.map((exercise, index) => (
                    <div key={index} className="flex gap-3 md:gap-6 max-w-[440px] items-center">
                        <div className="flex flex-col gap-1.5 relative">
                            <div className='absolute top-0 left-0 w-full flex justify-center'>
                                <div className={`h-14 -mt-14 w-2 rounded-full ml-1
                                    ${exercise.type === nextExerciseType || exercises.findIndex((ex) => ex.type === nextExerciseType) > index ? "bg-bg-success" : "bg-bg-tertiary"}`}
                                />
                            </div>
                            {index !== exercises.length - 1 && (
                                <div className='absolute bottom-0 left-0 w-full flex justify-center'>
                                    <div className={`h-14 -mb-14 w-2 rounded-full ml-1
                                        ${exercises.findIndex((ex) => ex.type === nextExerciseType) > index ? "bg-bg-success" : "bg-bg-tertiary"}`}
                                    />
                                </div>
                            )}
                            <div className={`flex justify-center mt-4 mb-4 h-14 w-14 sm:h-20 sm:w-20 rounded-full p-2 
                                ${exercise.type === nextExerciseType || exercises.findIndex((ex) => ex.type === nextExerciseType) > index ? "bg-bg-success" : "bg-bg-tertiary"}`}
                            >
                                <img src={exercise.imgSrc} alt={exercise.imgAlt} />
                            </div>
                        </div>
                        <div className={`flex flex-col gap-3 px-4 py-3 flex-1 ${exercise.type === nextExerciseType && "rounded-lg bg-bg-secondary"}`}
                        >
                            <h2 className={`text-lg font-medium ${exercise.type === nextExerciseType ? "text-text-primary" : "text-text-tertiary"}`}>
                                {exercise.title}
                            </h2>
                            <div className={`flex flex-col gap-2 text-sm ${exercise.type === nextExerciseType ? "text-text-secondary" : "text-text-tertiary"}`}>
                                <p className='website-p'>{exercise.description}</p>
                                <p className="font-medium text-sm">{exercise.numberOfWords}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <FooterControls>
                <div className="footer-controls-action-div">
                    <Button 
                        size="control"
                        variant="secondary"
                        leadingIcon={ChevronLeftSecondary}
                        onClick={() => {
                            setIsLoading(true);
                            handleNewChapter(navigate, getTextLangFrom, getTextLangTo, user, setIsLoading, undefined, chatId);
                            setIsLoading(false);
                        }}
                        text={getTextLangFrom('word-new-chapter')}
                    />
                    <Button 
                        size="control"
                        trailingIcon={ChevronRightWhite}
                        onClick={startVocabularyGame}
                        text={getTextLangFrom('word-next-exercise')}
                    />
                </div>
            </FooterControls>
        </EndGameLayout>
    );
};

export default EndChapterPage;
