import React from 'react';
import { GameCard } from './ExerciseCard';
import { useLanguage } from '../../languages/LanguageContext';
import { MainLayout } from '../baseComponents/Layout';
import flashcardImage from '../../assets/home-flashcard.svg';
import writeImage from '../../assets/home-write.svg';
import multipleChoiceImage from '../../assets/home-multiple-choice.svg';

const ExercisePage: React.FC = () => {
  const { getTextLangFrom } = useLanguage();

  return (
    <MainLayout 
      hasNavbar={true}
      title={getTextLangFrom('word-exercises')}
    >
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 lg:gap-8">
        <GameCard title={getTextLangFrom('word-multiple-choice')} image={multipleChoiceImage} gameMode="multiple-choice-mode" exerciseType="vocabulary" />
        <GameCard title={getTextLangFrom('word-flashcard')} image={flashcardImage} gameMode="flashcard-mode" exerciseType="vocabulary" />
        <GameCard title={getTextLangFrom('word-write')} image={writeImage} gameMode="write-mode" exerciseType="vocabulary" />
      </div>
    </MainLayout>
  );
};

export default ExercisePage;