import React, { useEffect, useState } from 'react';
import { useAuth } from '../../authentification/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../languages/LanguageContext';
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import GoogleIcon from '../../assets/google_icon.svg';
import LoadingAnimation from '../baseComponents/LoadingAnimation';
import fetchValidClientId from '../../authentification/clientId';

const GoogleLoginButton: React.FC<{ setError: (error: string) => void }> = ({ setError }) => {
  const { loginWithGoogle, checkUserStatus } = useAuth();
  const navigate = useNavigate();
  const { getTextLangFrom } = useLanguage();
  const [clientId, setClientId] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const initializeGoogleAuth = async () => {
      const validClientId = await fetchValidClientId();
      setClientId(validClientId);
      GoogleAuth.initialize({
        clientId: validClientId,
        scopes: ['email'],
        grantOfflineAccess: true,
      });
    };
    initializeGoogleAuth();
  }, []);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      const googleUser = await GoogleAuth.signIn();
      //console.log('Google User:', googleUser);
      const email = googleUser.email.toLowerCase();
  
      if (!email) {
        console.error('No email found in id_token');
        setError(getTextLangFrom('LoginPage-login-failed'));
        return;
      }
  
      const userStatus = await checkUserStatus(email);
      setIsLoading(false);

      if (userStatus.status === 'first-time-user' || userStatus.status === 'active-user') {
        await loginWithGoogle(email);
        if (userStatus.status === 'first-time-user') {
          navigate('/onboarding-welcome');
        } else {
          navigate('/library');
        }
      } else if (userStatus.status === 'waitlisted') {
        const waitlistPosition = userStatus.waitlistPosition;
        navigate('/waitlist', { state: { waitlistPosition } });
      } else {
        console.error('Server error');
        setError(getTextLangFrom('LoginPage-server-error'));
      }
    } catch (error) {
      console.error('Error signing in with Google:', error);
      setError(getTextLangFrom('LoginPage-server-error'));
      setIsLoading(false);
    }
  };

  return (
    <button
      className={`border box-shadow-xs font-medium flex items-center justify-center w-full 
         bg-buttonSecondary-bg text-buttonSecondary-fg border-buttonSecondary-border 
         hover:bg-buttonSecondary-bgHover hover:border-buttonSecondary-borderHover hover:text-buttonSecondary-fgHover
         md:w-auto rounded-lg px-4 py-2 gap-2`}
      onClick={handleClick}
    >
      <img src={GoogleIcon} alt="Google" className='h-8 w-8' />
      {getTextLangFrom('LoginPage-google-login')}
      <LoadingAnimation isLoading={isLoading} />
    </button>
  );
};

export default GoogleLoginButton;
