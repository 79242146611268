import React from 'react';
import { ChapterForNavigation } from '../../types';
import { useLanguage } from '../../languages/LanguageContext';
import { ReactComponent as FaChevronDown } from '../../assets/chevron-down.svg';
import { ReactComponent as FaChevronRight } from '../../assets/chevron-right.svg';
import Button from '../baseComponents/Button';
import { parseTextWithMask } from '../book/bookUtils/messageTextParser';

const ChapterList = ({
  chaptersForNavigation,
  openChapterNumber,
  setOpenChapterNumber,
  selectedChapterNumber,
  setSelectedChapterNumber,
  expandedChapterNumber,
  setExpandedChapterNumber,
}: {
  chaptersForNavigation: ChapterForNavigation[];
  openChapterNumber: number | null;
  setOpenChapterNumber: (index: number) => void;
  selectedChapterNumber: number | undefined;
  setSelectedChapterNumber: (number: number) => void;
  expandedChapterNumber: number | null;
  setExpandedChapterNumber: (e: React.MouseEvent<HTMLButtonElement>, number: number) => void;
}) => {
  const { getTextLangFrom, getTextLangTo } = useLanguage();
  // console.log("chaptersForNavigation", chaptersForNavigation);

  return (
    <ul className="list-none p-0 m-0 overflow-y-auto h-full">
      {chaptersForNavigation.map((chNav, index) => {
        const isSelectedChapter = chNav.chapters?.some((chapter) => chapter.number === selectedChapterNumber);
        return (
          <li key={index} className="mb-2">
            <div
              className={`flex items-center p-2 gap-2 cursor-pointer text-lg font-medium ${isSelectedChapter && 'text-text-brand'}`}
              onClick={() => setOpenChapterNumber(index)}
            >
              <div className={`transition-transform duration-300 ${openChapterNumber === index ? 'rotate-180' : ''}`}>
                {openChapterNumber === index ? <FaChevronDown /> : <FaChevronRight />}
              </div>
              <span>{chNav.title || getTextLangFrom('word-chapter') + ' ' + (index + 1)}</span>
            </div>
            {openChapterNumber === index && (
              <div className="flex flex-col gap-1 ">
                {chNav.chapters.map((chapter) => (
                  <div
                    key={chapter.number}
                    className={`flex flex-col p-3 gap-2 rounded-md cursor-pointer 
                      ${selectedChapterNumber === chapter.number ? 'bg-bg-tertiary' : 'hover:bg-bg-secondary'}`}
                    onClick={() => setSelectedChapterNumber(chapter.number)}
                  >
                    <span className="font-medium">{getTextLangTo('word-part')} {chapter.numberWithinOriginalChapter}</span>
                    <p className="text-sm text-text-secondary">{parseTextWithMask(chapter.text.split('\n')[0])}</p>
                    {expandedChapterNumber === chapter.number && chapter.text.split('\n').slice(1).map((paragraph, idx) => (
                      <div key={idx} className="text-sm text-text-secondary">{parseTextWithMask(paragraph)}</div>
                    ))}
                    <Button
                      onClick={(e) => setExpandedChapterNumber(e, chapter.number)}
                      text={expandedChapterNumber === chapter.number ? getTextLangFrom('word-show-less') : getTextLangFrom('word-show-more')}
                      variant="tertiary"
                      isLink={true}
                      isJustifyStart={true}
                    />
                  </div>
                ))}
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default ChapterList;
