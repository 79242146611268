import React, { useEffect } from 'react';
import FooterControls from '../baseComponents/FooterControls';
import { BookExercise, ExerciseState, getButtonVariantForExerciseState } from '../../types';
import ChevronRight from '../../assets/chevron-right-white.svg';
import { ResultsDisplay } from '../games/GameComponents/FooterExerciseInfo';
import Button from '../baseComponents/Button';

interface FooterControlsProps {
  bookExercises: (string | BookExercise)[];
  exerciseState: ExerciseState
  focusedExerciseIndex: number;
  messageRole: string;
  buttonText: string;
  handleClick: (userResponse: string) => Promise<void>;
  getTextLangFrom: (key: string) => string;
}

const BookFooterControls: React.FC<FooterControlsProps> = ({
  bookExercises,
  exerciseState,
  focusedExerciseIndex,
  messageRole,
  buttonText,
  handleClick,
  getTextLangFrom,
}) => {
  const [lastFocusedExerciseIndex, setLastFocusedExerciseIndex] = React.useState<number>(-1);
  // console.log("focusedExerciseIndex", focusedExerciseIndex);

  useEffect(() => {
    if (focusedExerciseIndex >= 0) {
      setLastFocusedExerciseIndex(focusedExerciseIndex);
    } else if (focusedExerciseIndex === -2) {
      setLastFocusedExerciseIndex(-1);
    }
  }, [focusedExerciseIndex, lastFocusedExerciseIndex]);

  // console.log("lastFocusedExerciseIndex", lastFocusedExerciseIndex, "focusedExerciseIndex", focusedExerciseIndex);
  
  return (
    <FooterControls messageRole={messageRole} exerciseState={exerciseState}>
      <ResultsDisplay
        lemmaLangTo={(focusedExerciseIndex >= 0) ? (bookExercises[focusedExerciseIndex] as BookExercise).lemmaLangTo : ''}
        exerciseState={exerciseState}
        messageRole={messageRole}
        pos={(focusedExerciseIndex >= 0) ? (bookExercises[focusedExerciseIndex] as BookExercise).pos : ''}
        lemmaLangFrom={(focusedExerciseIndex >= 0) ? (bookExercises[focusedExerciseIndex] as BookExercise).lemmaLangFrom : ''}
      />
      <div className="footer-controls-action-div">
        {(exerciseState === "loading") ? (
          <Button
            variant="secondary"
            size="control"
            disabled
            onClick={() => {}}
            text={getTextLangFrom('word-loading-dots')}
          />
        ) : (focusedExerciseIndex < 0) ? (
          <Button 
            size="control"
            variant={getButtonVariantForExerciseState(exerciseState)}
            trailingIcon={ChevronRight}
            onClick={() => handleClick('')}
            text={buttonText}
          />
        ) : (messageRole === 'book-translate') ? (
          <Button 
            size="control"
            variant={getButtonVariantForExerciseState(exerciseState)}
            trailingIcon={ChevronRight}
            onClick={() => handleClick('')}
            text={buttonText}
          />
        ) : (
          <>ERROR</>
        )}
      </div>
    </FooterControls>
  );
};

export default BookFooterControls;
