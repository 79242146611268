import { terminateGame } from "../../../api/Games";
import { Card, ExerciseRecord, Message, BookExercise, GameState, ExerciseState } from "../../../types";
import { useNavigate } from 'react-router-dom';

function bookExerciseToCard(bookExercise: BookExercise): Card {
  // console.log("bookExercise:", bookExercise);
  return {
    translationId: bookExercise.translationId,
    lemmaLangFrom: bookExercise.lemmaLangFrom,
    exampleLangFrom: bookExercise.exampleLangFrom,
    lemmaLangTo: bookExercise.lemmaLangTo,
    exampleLangTo: bookExercise.exampleLangTo,
    score: bookExercise.score ?? false, // Default to `false` if `score` is not provided
    pos: bookExercise.pos, // If no `pos`, you may choose a default
  };
}


function evaluateCardScore(exerciseState: ExerciseState): boolean | undefined {
  if (exerciseState === 'correct' || exerciseState === 'almost-correct') {
    return true;
  } else if (exerciseState === 'wrong') {
    return false;
  } else {
    return undefined;
  }
}

const handleTerminateChapter = async (navigate: ReturnType<typeof useNavigate>, userId: number, chatId: number, 
  chapterMessages: Message[], initialTime: Date, isEndOfCard: boolean,
) => {
  const gameMode = 'book-mode';
  let totalExercises = 0;
  let wrongAnswers = 0;

  let exerciseRecords = [] as ExerciseRecord[];
  let cardsForReviewExercise = [] as Card[];

  // console.log('chapterMessages', chapterMessages)

  chapterMessages.forEach((message: Message) => {
    if (message.bookExercises) {
      message.bookExercises.forEach((exercise: string | BookExercise) => {
        if (typeof exercise !== 'string') {           
          // console.log("isexercise:", exercise.isExercise);
          const score = evaluateCardScore(exercise.exerciseState);
          if (exercise.isExercise === true && score !== undefined) {
            // console.log("Saving exercise:", exercise);
            exerciseRecords.push({
              translationId: exercise.translationId,
              userResponse: exercise.userResponse,
              score: score,
            });
            totalExercises++;
            if (score === false) {
              wrongAnswers++;
              cardsForReviewExercise.push(bookExerciseToCard(exercise));
            }
          }
        }
      });
    }
  });

  // console.log("Total exercises:", totalExercises, "Wrong answers:", wrongAnswers, "Accuracy:", (totalExercises - wrongAnswers) / totalExercises);
  terminateGame(userId, gameMode, exerciseRecords, chatId);

  // TEMPORARY FOR TESTING
  // cardsForReviewExercise = cardsForReviewExercise.slice(0, 1);
  
  // if it is the end of the card, navigate to the endgame page. Otherwise, navigate to the library
  if ( isEndOfCard ) {
    const gameState = {
      gameMode: gameMode,
      chatId: chatId,
      accuracy: (totalExercises - wrongAnswers) / totalExercises,
      gameDuration: (new Date().getTime() - initialTime.getTime()) / 1000,
      nextExerciseType: cardsForReviewExercise.length > 0 ? 'book-chapter-review' : 'book-general-review',
      cards: cardsForReviewExercise,
      numberCardsToReview: cardsForReviewExercise.length,
    } as GameState;

    navigate('/endgame', { state: { gameState } });
  } else {
    navigate('/library');
  }
}


export default handleTerminateChapter;