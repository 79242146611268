import React from 'react';
import ArrowIconLightGreen from '../../assets/arrow-narrow-right-light-green.svg';
import ArrowIconDarkGreen from '../../assets/arrow-narrow-right-dark-green.svg';
import { BookExercise, ExerciseState, Theme } from '../../types';

interface SolutionTextProps {
  part: BookExercise;
  theme: Theme;
  handleShowTooltip: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, part: BookExercise | string) => void;
}

const SolutionTextWrapper: React.FC<SolutionTextProps> = ({ part, theme, handleShowTooltip }) => {
  const ArrowIcon = theme === 'light' ? ArrowIconDarkGreen : ArrowIconLightGreen;

  const getTextClasses = (exerciseState?: ExerciseState) => {
    if (exerciseState === "wrong") return 'text-text-error line-through';
    if (exerciseState === "almost-correct") return 'text-text-warning line-through';
    return 'text-text-success';
  };

  return part.exerciseState === "correct" ? (
    <span 
      className={`inline-block font-medium cursor-pointer ${getTextClasses(part.exerciseState)}`} 
      onClick={(event) => handleShowTooltip(event, part)}
    >
      {part.solution}
    </span>
  ) : (
    <>
      <span className={`inline-block font-medium ${getTextClasses(part.exerciseState)}`}>
        {part.userResponse}
      </span>
      <span className="inline-block font-medium">
        <span className="inline-block">
          &nbsp;<img src={ArrowIcon} alt="Arrow" className="inline-block w-6 h-6" />&nbsp;
        </span>
      </span>
      <span 
        className="inline-block font-medium cursor-pointer text-text-success" 
        onClick={(event) => handleShowTooltip(event, part)}
      >
        {part.solution}
      </span>
    </>
  );
};

export default SolutionTextWrapper;
