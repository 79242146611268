import React from 'react';
import { useLanguage } from '../../languages/LanguageContext';
import PagesReadIcon from '../../assets/bookmark.svg';
import WordsLearnedIcon from '../../assets/trophy-01.svg';
import StreakIconActive from '../../assets/streakIconActive.svg';
import StreakIconLastDay from '../../assets/streakIconLastDay.svg';
import StreakIconInactive from '../../assets/streakIconInactive.svg';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { useAuth } from '../../authentification/AuthContext';
import { ProfileData } from '../../types';

ChartJS.register(CategoryScale, LinearScale, BarElement);

export const StreakDays: React.FC<{ lastWeekDailyStats: { day: string, isStreak: boolean }[] }> = ({ lastWeekDailyStats }) => {
  return (
    <div className="flex justify-around gap-2 sm:gap-4 w-full">
      {lastWeekDailyStats.map((stats, index) => (
        <div key={index} className="flex flex-col items-center gap-1 sm:gap-2">
          <div className='text-md font-medium text-text-tertiary'>{stats.day}</div>
          <div className={`flex justify-center items-center w-6 h-6 sm:w-8 sm:h-8  rounded-full 
              ${stats.isStreak ? 'bg-brand-yellow' : // if streak
                (index === lastWeekDailyStats.length - 1 && !stats.isStreak && lastWeekDailyStats[index - 1].isStreak) ? 'bg-bg-quaternary' : // if last day of the week and not a streak, but the previous day was a streak
                'bg-bg-tertiary' }`} // if not a streak
              >
            {stats.isStreak && <p className="font-bold text-lg text-white">✓</p>}
          </div>
        </div>
      ))}
    </div>
  )
}

export const getOrderedDaysOfWeek = () => {
  const today = new Date().getDay();
  const daysOfWeek = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  return [...daysOfWeek.slice(today), ...daysOfWeek.slice(0, today)];
}

export const DailyStreakTile: React.FC = () => {
  const { getTextLangFrom } = useLanguage();
  const { user } = useAuth();

  const orderedDaysOfWeek = getOrderedDaysOfWeek();
  const lastWeekDailyStats = (user?.last7dStreakDays || []).map((isStreak, index) => ({
    day: orderedDaysOfWeek[index],
    isStreak,
  }));

  const StreakIcon = 
    user?.last7dStreakDays?.[6] ? StreakIconActive : 
    (user?.last7dStreakDays?.[5] && !user?.last7dStreakDays?.[6]) ? StreakIconLastDay : 
    StreakIconInactive;

  // console.log('last7dStreakDays', user?.last7dStreakDays)
  return (
    <div className="tile">
      <div className={'flex items-center gap-2'}>
        <div
          className={`flex text-2xl font-semibold items-center justify-center w-20 h-20 font-bold pt-2.5 pr-1 text-text-tertiary ${user?.last7dStreakDays?.[6] && 'text-white'}`}
          style={{
            backgroundImage: `url(${StreakIcon})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          {user?.numberStreakDays? user.numberStreakDays : 0}
        </div>
        <div className="flex flex-col flex-1 gap-1">
          <h3 className="font-semibold text-2xl">{getTextLangFrom('Streak-title')}</h3>
          <p className="small-text">{getTextLangFrom('EndGameStreakPage-text')}</p>
        </div>
      </div>
      <StreakDays lastWeekDailyStats={lastWeekDailyStats} />
    </div>
  );
};

const NumberTile: React.FC<{ icon: string, number: number, text: string }> = ({ icon, number, text }) => {
  return (
    <div className="tile">
      <div className="flex gap-3">
        <img src={icon} alt="icon" className="w-6 h-6 mt-0.5" />
        <div>
          <h3 className="tile-h3">{number}</h3>
          <p className="small-text">{text}</p>
        </div>
      </div>
    </div>
  );
}


const PagesReadTile: React.FC<{ profileData: ProfileData }> = ({ profileData }) => {
  const { getTextLangFrom } = useLanguage();

  const getColor = (color: string) => {
    return getComputedStyle(document.documentElement).getPropertyValue(color)
  }

  const pagesReadData = {
    labels: getOrderedDaysOfWeek(),
    datasets: [
      {
        data: profileData.pagesReadPerDay,
        backgroundColor: getColor('--brand-500'),
        borderRadius: 4,
        barPercentage: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Ensure the chart always fills the container
    animation: {
      duration: 0, // Disable animation on page load
    },
    plugins: {
      tooltip: {
        enabled: false, // Disable tooltips
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Disable vertical grid lines
        },
        ticks: {
          color: getColor('--text-tertiary'),
        },
        border: {
          display: false, // Remove the axis line next to Y labels
        },
      },
      y: {
        grid: {
          color: getColor('--border-secondary'),
          borderDash: [4, 4],
        },
        ticks: {
          color: getColor('--text-tertiary'),
          maxTicksLimit: 6, // Limit number of Y-axis labels to 5
        },
        min: 0, // Set minimum value for Y-axis
        suggestedMax: 10, // Ensure Y-axis extends up to at least 10
        border: {
          display: false, // Remove the axis line next to Y labels
        },
      },
    },
  };
  
  return (
    <div className="tile">
      <h3 className="tile-h3">{getTextLangFrom('ProfilePage-pages-read')}</h3>
      <div className="flex justify-center w-full h-[200px]">
        <Bar data={pagesReadData} options={options} />
      </div>
    </div>
  );
};

export default PagesReadTile;


export const StatsTiles: React.FC<{ profileData?: ProfileData }> = ({ profileData }) => {
  const { getTextLangFrom } = useLanguage();

  return (
    <div className="flex flex-col gap-8 pb-4 md:pb-12">
      { profileData && (
        <>
          <DailyStreakTile />
          <div className="flex gap-8 flex-col sm:flex-row">
            <NumberTile
              icon={PagesReadIcon}
              number={profileData.pagesReadPerDay.reduce((a, b) => a + b, 0)}
              text={getTextLangFrom('ProfilePage-pages-read-last-7d')}
            />
            <NumberTile
              icon={WordsLearnedIcon}
              number={profileData.numberWordsLearned}
              text={getTextLangFrom('ProfilePage-words-learned')}
            />
          </div>
          <PagesReadTile profileData={profileData} />
        </>
      )}
    </div>
  );
};
