import React, { useEffect, useState } from 'react';
import ToastIcon from '../../assets/check-circle-broken-green.svg';
import Button from './Button';

interface ToastProps {
  title: string;
  subtitle?: string;
  buttonText?: string;
  onButtonClick?: () => void;
  showToast: boolean;
  autoDismissTimeout?: number;
  setShowToast: (showToast: boolean) => void;
}

const Toast: React.FC<ToastProps> = ({
  title,
  subtitle,
  buttonText,
  onButtonClick,
  showToast,
  autoDismissTimeout = 3000,
  setShowToast,
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (showToast) {
      setVisible(true);
      const timer = setTimeout(() => {
        setShowToast(false);
        if (onButtonClick) {
          onButtonClick();
        }
      }, autoDismissTimeout);

      return () => clearTimeout(timer);
    }
  }, [showToast, autoDismissTimeout, onButtonClick, setShowToast]);

  return (
    <div
      className={`fixed bottom-3 right-3 z-50 max-w-sm w-full p-4 rounded-lg
        bg-bg-primary border border-border-secondary shadow-md 
        transition-transform duration-300 ease-in-out ${visible ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'} ${showToast ? 'block' : 'hidden'}`}
      style={{
        animation: `${showToast ? 'slideIn' : 'slideOut'} 0.3s forwards`,
      }}
    >
      <div className="flex items-center gap-6">
        <img src={ToastIcon} alt="toast-icon" className="w-8 h-8 mt-0.5 mb-auto" />
        <div className="flex flex-col gap-2 flex-1">
          <div className="font-semibold text-md text-text-primary">{title}</div>
          {subtitle && <div className="text-sm text-text-secondary">{subtitle}</div>}
        </div>
        {buttonText && onButtonClick && (
          <Button variant="secondary" onClick={onButtonClick} text={buttonText} />
        )}
      </div>
    </div>
  );
};

export default Toast;
