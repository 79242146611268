import React, { Dispatch, SetStateAction } from 'react';
import Tag from '../baseComponents/Tag';
import { Book, User } from '../../types';
import { useNavigate } from 'react-router-dom';
import BookCardProgress from './BookCardProgress';
import DropDownCustom from './DropDownCustom';
import { useLanguage } from '../../languages/LanguageContext';
import handleNewChapter from '../book/bookUtils/startChapter';

interface BookCardProps {
  book: Book;
  user: User;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setSelectedOption: (option: string) => void;
  setSelectedBookId: (id: number) => void;
}

const BookCard: React.FC<BookCardProps> = ({
  book,
  user,
  setIsLoading,
  setSelectedOption,
  setSelectedBookId,
}) => {
  const navigate = useNavigate();
  const { getTextLangFrom, getTextLangTo } = useLanguage();

  // console.log('BookCard', book);

  return (
    <div className="flex relative gap-2">
      <button
        className={`group flex-1 flex p-2 rounded-md transition duration-200
            hover:bg-buttonTertiary-bgHover hover:border-buttonTertiary-borderHover hover:text-buttonTertiary-fgHover hover:shadow-md`}
        onClick={() => {
          if (book.progress === 100) {
            setSelectedBookId(book.id);
            setSelectedOption('Navigate');
          } else {
            handleNewChapter(navigate, getTextLangFrom, getTextLangTo, user, setIsLoading, book.id);
          }
        }}
      >
        <div className="flex flex-row gap-3 sm:gap-5">
          <div className="flex h-24 w-[59px] sm:h-36 sm:w-[89px] justify-center items-center">
            {book.coverImage ? (
              <img
                src={book.coverImage}
                alt={book.title}
                className="object-cover h-full bg-bg-full rounded-sm shadow-md border border-border-secondary"
              />
            ) : (
              <div className="flex flex-col gap-2 items-center justify-start p-3 bg-bg-tertiary shadow-md rounded-sm overflow-hidden h-full w-full
                group-hover:bg-tag-bgTertiary transition duration-200 border border-border-secondary">
                <h3 className="text-[6px] md:text-[10px] font-semibold text-text-secondary uppercase"> {book.title}</h3>
                <p className="text-[5.6px] md:text-[8px] font-normal text-text-tertiary italic">{book.author}</p>
              </div>
            )}
          </div>
          <div className="flex-1 flex flex-col gap-1 text-left">
            <h2 className="text-md sm:text-lg font-medium text-text-primary">{book.title}</h2>
            <p className="text-sm sm:text-md font-normal text-text-tertiary mb-1">{book.author}</p>
              {book.progress === 100 ? (
                <Tag variant='primary' text="Completed" />
              ) : book.progress !== 0 && book.progress !== undefined ? (
                <div className='flex gap-2'>
                    <BookCardProgress progress={book.progress} />
                    <div className='flex gap-1.5 text-xs sm:text-sm font-medium'>{book.progress}%<span className="hidden sm:block">{getTextLangFrom('BookCard-read')}</span></div>
                </div >
              ) : (
                <></>
              )}
          </div>
        </div>
      </button>
      <DropDownCustom
        options={book.lastAccessed ? ['Navigate', 'Edit', 'Delete'] : ['Navigate', 'Edit']}
        setSelectedOption={setSelectedOption}
        id={book.id}
        setSelectedId={setSelectedBookId}
      />
    </div>
  );
};

export default BookCard;
