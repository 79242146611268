import React from 'react';
import Navbar from '../appComponents/Navbar';
import StreakInfo from './StreakInfo';

interface MainLayoutProps {
  children: React.ReactNode;
  hasNavbar: boolean;
  hasFooterControls?: boolean;
  className?: string;
  title?: string;
  hideStreak?: boolean;
  isFullHeight?: boolean;
  customPadding?: string;
  isFullWidth?: boolean;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children, hasNavbar, hasFooterControls, className = '', title, hideStreak, isFullHeight, isFullWidth, customPadding }) => {
  return (
    <section className={`flex flex-col relative text-left text-text-primary w-screen h-screen bg-bg-primary 
      ${hasFooterControls && 'padding-bottom-with-navbar'}
      ${hasNavbar && 'lg:flex-row pb-[70px] md:pb-0'}
      ${className}`}
    >
      {hasNavbar && <Navbar />}
      <div className={`relative flex flex-col items-center w-full overflow-y-auto ${isFullHeight && 'h-full'} ${customPadding ? customPadding : 'layout-padding-x layout-padding-y'}`}>
        <div className={`relative flex flex-col ${!isFullWidth && 'max-w-[800px]'} w-full gap-6 mg:gap-8 ${isFullHeight && 'h-full'}`}>
          {title && 
            <div className="flex w-full justify-between items-center">
              <div className="flex flex-col w-full items-start"> 
                <h1 className="text-2xl font-semibold md:text-3xl">{title}</h1>
              </div>
              { !hideStreak && <StreakInfo />}
            </div>
          }
          {children}
        </div>
      </div>
    </section>
  );
};
