import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { Theme } from '../types';
import { useAuth } from '../authentification/AuthContext';

const ThemeContext = createContext({
  theme: 'light' as Theme,
  setTheme: (theme: Theme) => {},
});

export const useTheme = () => useContext(ThemeContext);


export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth();
  const [theme, setTheme] = useState<Theme>('light'); // Default theme

  useEffect(() => {
    changeTheme(user?.theme || 'light');
  }, [user]);

  const changeTheme = (newTheme: Theme) => {
    const root = document.documentElement;
    root.classList.remove('light', 'dark', 'dark-dusk');
    root.classList.add(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
