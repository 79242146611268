import React, { useState, useRef } from 'react';
import { ButtonVariants } from '../../types';
import Tooltip from './Tooltip';

interface ButtonProps {
  text?: string;
  variant?: ButtonVariants;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'control';
  onClick?: (event: any) => void;
  onBlur?: (event: any) => void;
  onMouseEnter?: (event: any) => void;
  disabled?: boolean;
  leadingIcon?: string;
  trailingIcon?: string;
  iconOnly?: boolean;
  className?: string;
  isActive?: boolean;
  isLink?: boolean;
  isJustifyStart?: boolean;
  tooltip?: { text: string; position: 'top' | 'bottom' | 'left' | 'right' };
  ref?: React.RefObject<HTMLButtonElement>;
  key?: number;
}

const Button: React.FC<ButtonProps> = ({
  text,
  variant = 'primary',
  size = 'md',
  onClick,
  onBlur,
  onMouseEnter,
  disabled = false,
  leadingIcon,
  trailingIcon,
  iconOnly = variant === 'menuMobile' ? true : false,
  className = '',
  isActive = false,
  isLink = false,
  isJustifyStart = false,
  tooltip,
  key,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const hoverTimeoutRef = useRef<number | null>(null);

  const handleMouseEnter = (event: React.MouseEvent) => {
    hoverTimeoutRef.current = window.setTimeout(() => {
      setShowTooltip(true);
    }, 500); // Set delay to 500ms
    if (onMouseEnter && !disabled) onMouseEnter(event);
  };

  const handleMouseLeave = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
    setShowTooltip(false);
  };

  const baseStyles = `flex font-medium items-center rounded-xl md:rounded-lg whitespace-nowrap focus:outline-none focus:ring-0
    ${(variant !== 'menuDesktop' && !isJustifyStart) && 'justify-center'}    
  `;

  const disabledIconStyles = `${disabled && 'opacity-20'}`;

  let sizeBaseStyles = {
    sm: 'text-sm gap-1.5',
    md: 'text-sm gap-3',
    lg: 'text-base gap-4',
    xl: 'text-lg gap-5',
    control: 'text-base gap-3 md:min-w-[170px] w-full',
  };

  if (variant === 'menuDesktop') {
    sizeBaseStyles[size] = 'w-full text-base gap-3';
  }

  let sizeIconDivStyles = {
    sm: 'w-8 h-8 px-0 py-0',
    md: 'w-10 h-10 px-0 py-0',
    lg: 'w-12 h-12 px-0 py-0',
    xl: 'w-14 h-14 px-0 py-0',
    control: 'w-10 h-10 px-0 py-0',
  };

  if (variant === 'menuMobile') {
    sizeIconDivStyles[size] = 'w-[50px] h-[50px]';
  }

  let sizeNotOnlyIconDivStyles = {
    sm: 'px-3 py-2',
    md: 'px-3.5 py-2.5',
    lg: 'px-4 py-2.5',
    xl: 'px-4 py-3',
    control: 'px-0 py-3',
  };

  if (variant === 'menuDesktop') {
    sizeNotOnlyIconDivStyles[size] = 'px-3 py-2';
  } else if (isLink) {
    sizeNotOnlyIconDivStyles[size] = 'px-0 py-0';
  }

  const sizeIconImgStyles = {
    sm: 'w-5 h-5',
    md: 'w-5 h-5',
    lg: 'w-5 h-5',
    xl: 'w-6 h-6',
    control: `w-6 h-6 ${leadingIcon && '-ml-3'} ${trailingIcon && '-mr-3'}`,
  };

  sizeIconImgStyles[size] = variant === 'menuMobile' ? 'w-10 h-10' : sizeIconImgStyles[size];

  const disabledStyles = 'bg-buttonDisabled-bg text-buttonDisabled-fg border border-buttonDisabled-border cursor-not-allowed';

  // Variant-specific styles
  const variantStyles = {
    primary: `${(!disabled) && 'hover:bg-buttonPrimary-bgHover hover:border-buttonPrimary-borderHover shadow-sm bg-buttonPrimary-bg'}
        ${!disabled ? `border border-buttonPrimary-border text-buttonPrimary-fg hover:text-buttonPrimary-fgHover` : disabledStyles}`,
    secondary: `${(!disabled) && 'hover:bg-buttonSecondary-bgHover hover:border-buttonSecondary-borderHover shadow-sm bg-buttonSecondary-bg'}
        ${!disabled ? `border border-buttonSecondary-border text-buttonSecondary-fg hover:text-buttonSecondary-fgHover` : disabledStyles}`,
    tertiary: `${(!disabled) && 'hover:bg-buttonTertiary-bgHover hover:border-buttonTertiary-borderHover hover:shadow-sm bg-buttonTetiary-bg'}
        ${!disabled ? 'border border-buttonTertiary-border text-buttonTertiary-fg hover:text-buttonTertiary-fgHover' : disabledStyles}`,
    success: `${(!disabled) && 'hover:bg-buttonSuccess-bgHover hover:border-buttonSuccess-borderHover shadow-sm bg-buttonSuccess-bg'}
        ${!disabled ? 'border border-buttonSuccess-border text-buttonSuccess-fg hover:text-buttonSuccess-fgHover' : disabledStyles}`,
    warning: `${(!disabled) && 'hover:bg-buttonWarning-bgHover hover:border-buttonWarning-borderHover shadow-sm bg-buttonWarning-bg'}
        ${!disabled ? 'border border-buttonWarning-border text-buttonWarning-fg hover:text-buttonWarning-fgHover' : disabledStyles}`,
    error: `${(!disabled) && 'hover:bg-buttonError-bgHover hover:border-buttonError-borderHover shadow-sm bg-buttonError-bg'}
        ${!disabled ? 'border border-buttonError-border text-buttonError-fg hover:text-buttonError-fgHover' : disabledStyles}`,
    menuDesktop: 'hover:shadow-sm text-left items-center justify-start border border-buttonTertiary-border hover:bg-buttonTertiary-bgHover hover:border-buttonTertiary-borderHover hover:text-buttonTertiary-fgHover',
    menuMobile: 'hover:shadow-sm border border-transparent',
  };

  
  const variantStylesLink = {
    primary: `${!disabled ? 'text-text-brand hover:text-text-primary' : disabledStyles}`,
    secondary: `${!disabled ? 'text-text-secondary hover:text-text-primary bg-buttonTetiary-bg' : disabledStyles}`,
    tertiary: `${!disabled ? 'text-text-tertiary hover:text-text-primary bg-buttonTertiary-bg' : disabledStyles}`,
    success: '', warning: '', error: '', menuDesktop: '', menuMobile: '', // No link styles for these variants
  }

  // Styles for the active state
  const activeStyles = 'bg-bg-tertiary border border-border-secondary';

  return (
    <button
      className={`${baseStyles} ${sizeBaseStyles[size]} 
        ${!isLink ? variantStyles[variant] : variantStylesLink[variant]}
        ${iconOnly ? sizeIconDivStyles[size] : sizeNotOnlyIconDivStyles[size]}
        ${className} ${isActive ? activeStyles : ''}`}
      onClick={!disabled ? onClick : undefined}
      onBlur={!disabled ? onBlur : undefined}
      onMouseEnter={handleMouseEnter}
      disabled={disabled}
      onMouseLeave={handleMouseLeave}
    >
      {leadingIcon && <img src={leadingIcon} alt="Icon" className={`${sizeIconImgStyles[size]} ${disabledIconStyles}`} />}
      {!iconOnly && text}
      {trailingIcon && <img src={trailingIcon} alt="Icon" className={`${sizeIconImgStyles[size]} ${disabledIconStyles}`} />}
      {tooltip && tooltip.text && (
        <Tooltip text={tooltip.text} position={tooltip.position} isVisible={showTooltip} />
      )}
    </button>
  );
};

export default Button;
