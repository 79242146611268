import { useNavigate } from 'react-router-dom';
import { initializeVocabularyGame } from '../api/Games';
import { Card, GameMode, GameState, ExerciseType, User, gameModeToUrl } from '../types';

const handleStartVocabularyGame = async (
  gameMode: GameMode,
  exerciseType: ExerciseType,
  user: User,
  navigate: ReturnType<typeof useNavigate>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  chatId?: number,
  cards?: Card[],
  numberCardsToReview?: number,
  ) => {
    if (!user) {
        navigate('/library');
        return null;
    }

    setIsLoading(true);

    // If no cards are provided, retrieve them from the server
    if (!cards) {
        const newCards= await initializeVocabularyGame(user.id, gameMode );
        cards = newCards;
    }

    const gameData: GameState = {
        gameMode: gameMode,
        chatId: chatId,
        nextExerciseType: exerciseType,
        cards: cards,
        numberCardsToReview: numberCardsToReview,
    };
    
    setIsLoading(false);
    navigate(gameModeToUrl(gameMode), { state: { gameData } });
  };

export default handleStartVocabularyGame;
