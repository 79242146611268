import React from 'react';

interface MessageDivProps {
  children: React.ReactNode;
  messageRole: string;
}

const MessageDiv: React.FC<MessageDivProps> = ({
  children,
  messageRole,
}) => {
  const baseClasses = `flex mb-1 py-2 sm:py-3 rounded-lg text-lg duration-600 max-w-[600px] mx-auto`;
  const userClasses = `bg-bg-secondary shadow-md self-end`;
  const defaultClasses = `self-start`;
  const introClasses = `text-base font-medium uppercase max-w-[450px] text-center mx-auto`;
  const titleClasses = `text-2xl font-medium italic max-w-[450px] text-center mx-auto mt-6`;

  let roleClasses = '';
  if (messageRole === 'user') {
    roleClasses += userClasses;
  } else {
    roleClasses += defaultClasses;
  }

  if (messageRole === 'intro') {
    roleClasses += ` ${introClasses}`;
  } else if (messageRole === 'title') {
    roleClasses += ` ${titleClasses}`;
  }

  return (
    <div className={`${baseClasses} ${roleClasses}`} >
      {children}
    </div>
  );
};

export default MessageDiv;
