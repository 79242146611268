import React from 'react';
import LoadingAnimation from '../baseComponents/LoadingAnimation';


interface EndGameLayoutProps {
    title: string;
    isLoading: boolean;
    children: React.ReactNode;
}

const EndGameLayout: React.FC<EndGameLayoutProps> = ({ title, isLoading, children }) => {
    return (
        <div className='flex items-center flex-col text-text-primary w-screen h-screen bg-bg-primary padding-bottom-with-navbar layout-padding-x'>
            <div className="flex flex-col gap-6 max-w-[600px] h-full w-full pt-8 md:pt-12 pb-6 overflow-y-auto">
                <h1 className="text-center text-3xl font-semibold md:text-4xl">{title}</h1>
                <div className="flex flex-col items-center w-full gap-4">
                    {children}
                </div>
            </div>
            <LoadingAnimation isLoading={isLoading} />
        </div>
    );
};

export default EndGameLayout;

